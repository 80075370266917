<template>
    <div class="page1">
        <EButton type="primary" @click="dialogShow">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row)">
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row.takeReturnProjectId)">
                            删除
                        </EButton>
                        <EButton type="text" @click="change(scope.row.takeReturnProjectId,scope.row.status)">
                            {{scope.row.status==1?'禁用':'启用'}}
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 width="40%"
                 @handleClick="saveData"
                 :disabled="saveDisabled">
            <EForm
                    :formColumns="dialogFormColumns"
                    :rowSize="1"
                    :optionsBtn="false"
                    :actionBtn="true"
                    :formData="dialogForm"
                    ref="form"
                    :searchFlag="false"
                    :labelPosition="labelPosition"
                    :labelWidth="labelWidth"
                    :formRules="formRules"
            >
            </EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'Login',
    mixins:[otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '项目名称',
            prop: 'takeReturnProjectName'
          },
          {
            label: '计费方式',
            prop: 'chargeTypeCn'
          },
          {
            label: '新建时间',
            prop: 'createTime'
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '最近一次修改时间',
            prop: 'updateTime'
          },
          {
            label: '修改人',
            prop: 'updateUserCn'
          },
          {
            label: '状态',
            prop: 'statusCn'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        tableData: [],
        dialogFormColumns: [
          {
            title: '项目名称',
            type: 'text',
            property: 'takeReturnProjectName',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            title: '计费方式',
            type: 'radio',
            property: 'chargeType',
            placeHolder: '最多可录入20字',
            show: true,
            options:[
              {
                label:'按面积计算',
                value:1
              },
              {
                label:'固定费用',
                value:2
              }
            ]
          },
          {
            title: '说明',
            type: 'textarea',
            property: 'remark',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            type:'actionBtn',
            show:false
          }
        ],
        dialogVisible:false,
        labelPosition:'right',
        labelWidth:'100px',
        formRules:{
          takeReturnProjectName: vxRule(true, '',"blur", "项目名称不能为空"),
          chargeType:vxRule(true, '',"change", "计费方式不能为空"),
        },
        dialogForm:{
          takeReturnProjectName:'',
          remark:'',
          chargeType:1,
        },
        title:'新增收退费项目'
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      async changeStatus(id,status){
        let res=await Http.cmtakereturnprojectUpdStatus({id,status})
        if(res.code==200){
          this.$message.success(res.msg)
          this.getData()
        }
      },
      // 列表数据启用和禁用change方法
      change(price_uuid, status) {
        let msg = ''
        if (status == 1) {
          msg = '禁用'
        } else {
          msg = '启用'
        }
        this.$messageBox.confirm('确定' + msg + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.changeStatus(price_uuid, status==1?0:1)
        }).catch(res => {

        });
      },
      async cmtakereturnprojectRemove(ids){
        let res = await Http.cmtakereturnprojectRemove({ids})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      remove(id){
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cmtakereturnprojectRemove(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editDialog(data) {
        this.dialogVisible=true
        this.dialogForm = {
          takeReturnProjectId:data.takeReturnProjectId,
          takeReturnProjectName:data.takeReturnProjectName,
          remark:data.remark,
          chargeType:Number(data.chargeType),
        }
        this.title='修改收退费项目'

      },
      async cmtakereturnprojectSubmit(){
        this.setDisabled(true)
        let res = await Http.cmtakereturnprojectSubmit(this.dialogForm)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      dialogShow(){
        this.dialogVisible=true
      },
      cancelDialog(){
        this.dialogVisible = false
        this.dialogForm = {
          takeReturnProjectId:'',
          takeReturnProjectName:'',
          remark:'',
          chargeType:1,
        }
        this.title='新增收退费项目'
        this.$refs.form.$refs.form.resetFields()
      },
      showDialog(){
        this.dialogVisible=true
      },
      saveData() {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid) {
            this.cmtakereturnprojectSubmit()
          } else {
            return false;
          }
        })
      },
      async getData(){
        let res = await Http.getTakereTurnList()
        if(res.code == 200){
          this.tableData = res.data
        }
      }
    }
  }
</script>

<style lang="scss">

</style>
